import { DeviceDetails } from '@hero/hero-types';
import React from 'react';
import useGetDeviceLogSubscribe from '../api/useGetDeviceLogSubscribe';
import Button from '@hero/ui-kit/inputs/Button';
import DeviceWebsocketEvents from './DeviceWebsocketEvents';
import useDeviceLogSubscribe from '../api/useDeviceLogSubscribe';
import QueryInlineError from '../../../../components/QueryInlineError';

type Props = {
    deviceDetails: DeviceDetails;
};

const DeviceWebsocketLogs: React.FC<Props> = ({ deviceDetails }) => {
    const {
        data,
        refetch,
        isPending: isGetLoading
    } = useGetDeviceLogSubscribe({ serial: deviceDetails.serial });

    const { mutateAsync, isPending: isSubscribeLoading, error } = useDeviceLogSubscribe();

    const handleSubscribe = () => {
        mutateAsync({ serial: deviceDetails.serial }).then(() => refetch());
    };

    const isLoading = isGetLoading || isSubscribeLoading;

    return (
        <div>
            {data?.data.subscribed ? (
                <DeviceWebsocketEvents deviceDetails={deviceDetails} />
            ) : (
                <Button onClick={handleSubscribe} disabled={isLoading}>
                    {isLoading ? 'Loading...' : 'Subscribe'}
                </Button>
            )}

            {error ? <QueryInlineError centered error={error} /> : null}
        </div>
    );
};

export default DeviceWebsocketLogs;
