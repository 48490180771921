import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import { creditAmountSelector } from '@hero/redux-data/backoffice/userCredit/selectors';
import Button from '@hero/ui-kit/inputs/Button';
import A from '@hero/ui-kit/typography/A';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import FormattedAddress from '../../../components/FormattedAddress';
import getActiveDateFormatted from '../../../utils/getActiveDateFormatted';
import getStatusLabel from '../utils/getStatusLabel';
import getSubscriptionFeeFormatted from '../utils/getSubscriptionFeeFormatted';
import getUserCreditFormatted from '../utils/getUserCreditFormatted';
import getCardNumberFormatted from '../utils/getCardNumberFormatted';
import getCardExpiryFormatted from '../utils/getCardExpiryFormatted';
import Container from '@hero/ui-kit/layout/Container';
import P from '@hero/ui-kit/typography/P';
import { formatDateTime } from '@hero/hero-utils/date';

// RTM AND ORG
import {
    getRtmStatusDetails,
    rmaRequestDetailsReset
} from '@hero/redux-data/backoffice/rtmStatus/actionCreators';
import {
    rtmStatusDetailsErrorSelector,
    isRtmStatusDetailsLoadingSelector,
    rtmStatusDetailsSelector
} from '@hero/redux-data/backoffice/rtmStatus/selectors';
import { getOrganizationList } from '@hero/redux-data/backoffice/oldOrganizationList/actionCreators';
import {
    isOrganizationListLoadingSelector,
    organizationListSelector,
    organizationListErrorSelector
} from '@hero/redux-data/backoffice/oldOrganizationList/selectors';
import getRtmStatus from '../RTM/utils/getRtmStatus';
import { cancellationDetailsSelector } from '@hero/redux-data/backoffice/cancellationDetails/selectors';
import { BackofficeUserDeviceDetails } from '@hero/hero-types';
import InvoicesModal from './InvoicesModal';
import MembershipChangesModal from './MembershipChangesModal';
import { BackofficeMembershipDetails2 } from '../api/useGetMembershipDetails';
import envVars from '../../../constants/envVars';
import { formatPhoneNumber } from '@hero/hero-utils/phone';
import Modal from '@hero/ui-kit/components/Modal';
import Section from '@hero/ui-kit/layout/Section';
import useUnlockPaymentFailed from '../api/useUnlockPaymentFailed';
import QueryInlineError from '../../../components/QueryInlineError';

type Props = {
    onEditPlan: () => void;
    onAddCredit: () => void;
    onEditPayment: () => void;
    onEditTrackingNumber: () => void;
    membership?: BackofficeMembershipDetails2 | null;
    userDevice?: BackofficeUserDeviceDetails | null;
    fetchMembershipDetails: () => void;
};

const Details: React.FC<Props> = ({
    onEditPlan,
    onAddCredit,
    onEditPayment,
    onEditTrackingNumber,
    membership,
    userDevice,
    fetchMembershipDetails
}) => {
    const dispatch = useDispatch();
    const currentPlan = membership?.current_plan;
    const paymentCard = membership?.payment_card;
    const address = membership?.address;
    const device = userDevice?.device;
    const creditAmount = useSelector(creditAmountSelector);
    const cancellationDetails = useSelector(cancellationDetailsSelector, shallowEqual);

    // https://herohealth.atlassian.net/browse/HWM-13226 - disable plan change, hide btn
    // const isSubscriptionPlanEditable =
    //     membership && membership.status !== 'not-activated' && membership.status !== 'cancelled';
    const isSubscriptionPlanEditable = false;
    const isEditable = membership && membership.status !== 'not-activated';
    const buttonMargin = useComponentMargin({ marginLeft: 'large' });

    const currentPlanName = React.useMemo(() => {
        if (currentPlan?.name || currentPlan?.description) {
            return `${currentPlan?.name || currentPlan?.description} (${
                currentPlan?.storefront_key || currentPlan.key || '-'
            })`;
        }
        return '-';
    }, [currentPlan]);

    const isGrandfathered = !!membership?.grandfathered;

    // RTM Status

    React.useEffect(() => {
        dispatch(getOrganizationList({}));
    }, [dispatch]);

    const organizationList = useSelector(organizationListSelector, shallowEqual);
    const isOrgListLoading = useSelector(isOrganizationListLoadingSelector, shallowEqual);
    const orgListError = useSelector(organizationListErrorSelector, shallowEqual);

    React.useEffect(() => {
        const assureOrg = organizationList.find((org) => org.name?.toLowerCase() === 'assure');
        if (assureOrg && membership && membership.order_id) {
            dispatch(
                getRtmStatusDetails({
                    order_id: membership.order_id,
                    organization_id: assureOrg.id
                })
            );
        }
    }, [organizationList, dispatch, membership]);

    const rtmStatusDetails = useSelector(rtmStatusDetailsSelector, shallowEqual);
    const isRTMDetailsLoading = useSelector(isRtmStatusDetailsLoadingSelector, shallowEqual);
    const rtmStatusDetailsError = useSelector(rtmStatusDetailsErrorSelector, shallowEqual);

    const rtmStatus = React.useMemo(() => {
        if (isOrgListLoading || isRTMDetailsLoading) {
            return 'Checking...';
        }
        if (orgListError.error) {
            return `${orgListError.errorMessage}. ${orgListError.originalMessage}`;
        }
        if (rtmStatusDetailsError.error) {
            // show not found on error to avoid confusion
            return getRtmStatus('NOT_FOUND');
        }
        return getRtmStatus(rtmStatusDetails?.status);
    }, [
        isOrgListLoading,
        isRTMDetailsLoading,
        rtmStatusDetails,
        orgListError,
        rtmStatusDetailsError
    ]);

    React.useEffect(() => {
        return () => {
            dispatch(rmaRequestDetailsReset());
        };
    }, [dispatch]);

    const heroUserId = membership?.hero_user_id ? +membership.hero_user_id : undefined;

    const [showInvoices, setShowInvoices] = React.useState(false);
    const [showChanges, setShowChanges] = React.useState(false);

    const [showUnlockPaymentFailedModal, setShowUnlockPaymentFailedModal] = React.useState(false);
    const isUnlockPaymentFailedEnabled =
        membership?.status === 'payment-failed' || membership?.status === 'locked';

    const {
        mutateAsync: unlockPaymentFailed,
        isPending: isUnlockPaymentFailedPending,
        error: unlockPaymentFailedError
    } = useUnlockPaymentFailed();

    return membership ? (
        <div style={{ display: 'flex' }}>
            <Container gridTemplateColumns="2fr 3fr">
                <P strong>Status</P>
                <div style={{ display: 'flex', alignItems: 'center', height: '25px' }}>
                    <P noDefaultMargin>{getStatusLabel(membership.status)} </P>
                    <div title="Enabled only when membership status is payment failed or locked">
                        <Button
                            disabled={!isUnlockPaymentFailedEnabled}
                            size="small"
                            noDefaultMargin
                            styles={buttonMargin}
                            onClick={() => setShowUnlockPaymentFailedModal(true)}
                        >
                            Reset
                        </Button>
                    </div>
                </div>

                <P strong>Email</P>
                <P>
                    {membership.order_email ? (
                        membership.hero_user_id ? (
                            <A to={`/users/${membership.hero_user_id}`}>{membership.order_email}</A>
                        ) : (
                            membership.order_email
                        )
                    ) : (
                        '-'
                    )}
                </P>
                <P strong>Phone Number</P>
                <P>
                    {membership?.address?.phone ? formatPhoneNumber(membership.address.phone) : '-'}
                </P>
                <P strong>Device ID</P>
                <P>{device?.id ? <A to={`/devices/${device.id}`}>{device.id}</A> : '-'}</P>
                <P strong>Order #</P>
                <P>{membership.order_number || '-'}</P>
                <P strong>Tracking Number #</P>
                <P>
                    {membership?.fulfillment?.tracking_number || '-'}
                    <Button
                        size="small"
                        noDefaultMargin
                        styles={buttonMargin}
                        onClick={onEditTrackingNumber}
                    >
                        Edit
                    </Button>
                </P>
                <P strong>Remaining Credit</P>
                <P>
                    {getUserCreditFormatted(creditAmount)}
                    {/* https://herohealth.atlassian.net/browse/HWM-19087 - remove add credit */}
                    {isEditable && false && (
                        <Button
                            size="small"
                            noDefaultMargin
                            styles={buttonMargin}
                            onClick={onAddCredit}
                        >
                            + Add Credit
                        </Button>
                    )}
                </P>
                <P strong>Payment Method</P>
                <P>
                    {isEditable && (
                        <Button size="small" noDefaultMargin onClick={onEditPayment}>
                            Edit
                        </Button>
                    )}
                </P>
                <P>Name</P>
                <P>{paymentCard?.name_on_card || '-'}</P>
                <P>Card #</P>
                <P>{getCardNumberFormatted(paymentCard)}</P>
                <P>Exp Date</P>
                <P>{getCardExpiryFormatted(paymentCard)}</P>
                <P>Billing Address</P>
                <P>
                    <FormattedAddress address={address} />
                </P>
            </Container>
            <div>
                <Container
                    gridTemplateColumns="2fr 3fr"
                    gridColumnGap="regular"
                    styles={{ marginLeft: '3rem' }}
                >
                    {membership?.status === 'cancelled' ? (
                        <>
                            <P strong>Cancelled by</P>
                            <P>{cancellationDetails?.cancellation?.cancelled_by || 'N/A'}</P>
                        </>
                    ) : null}
                    {heroUserId ? (
                        <>
                            <P strong>Subscription Invoices</P>
                            <P>
                                <Button
                                    size="small"
                                    noDefaultMargin
                                    onClick={() => setShowInvoices(true)}
                                >
                                    Show
                                </Button>
                            </P>
                        </>
                    ) : null}

                    <P strong>Subscription Plan</P>
                    <P>
                        {currentPlanName}
                        {isSubscriptionPlanEditable ? (
                            <Button
                                size="small"
                                noDefaultMargin
                                styles={buttonMargin}
                                onClick={onEditPlan}
                            >
                                Edit
                            </Button>
                        ) : null}
                    </P>
                    <P strong>Subscription Stripe ID</P>
                    <P>
                        {membership?.stripe_subscription_id ? (
                            <A
                                to={`${envVars.STRIPE_DASHBOARD_BASE_URL}/subscriptions/${membership.stripe_subscription_id}`}
                            >
                                {membership.stripe_subscription_id}
                            </A>
                        ) : (
                            'N/A'
                        )}
                    </P>
                    <P strong>Subscription Fee</P>
                    <P>{getSubscriptionFeeFormatted(currentPlan)}</P>
                    <P strong>Next Invoice Date</P>
                    <P>
                        {membership?.next_renewal_date
                            ? formatDateTime(membership.next_renewal_date)
                            : 'N/A'}
                    </P>
                    <P strong>Prepaid until date</P>
                    <P>
                        {membership?.prepaid_end_date
                            ? formatDateTime(membership.prepaid_end_date)
                            : 'N/A'}
                    </P>
                    <P strong>Affiliate source</P>
                    <P>{membership?.affiliate_source || '-'}</P>
                    <P strong>Member since</P>
                    <P>
                        {membership?.hero_member_since
                            ? getActiveDateFormatted(membership?.hero_member_since)
                            : 'N/A'}
                    </P>
                    <P strong>Current Subscription Start Date</P>
                    <P>{getActiveDateFormatted(membership.activated_at)}</P>
                    <P strong>Commitment End Date</P>
                    <P>
                        {membership?.obligation_end_date
                            ? formatDateTime(membership.obligation_end_date)
                            : '-'}
                    </P>
                    {heroUserId ? (
                        <>
                            <P strong>{`List all D2C <> Org transfers`}</P>
                            <P>
                                <Button
                                    size="small"
                                    noDefaultMargin
                                    onClick={() => setShowChanges(true)}
                                >
                                    Show
                                </Button>
                            </P>
                        </>
                    ) : null}
                </Container>
                <Container
                    gridTemplateColumns="2fr 3fr"
                    gridColumnGap="regular"
                    styles={{ marginLeft: '3rem' }}
                >
                    <P strong>RTM Status</P>
                    <P>{rtmStatus}</P>
                </Container>
                {isGrandfathered ? (
                    <>
                        <hr />
                        <P>Grandfathered, plan change delayed for 12 months.</P>
                    </>
                ) : null}
            </div>
            {heroUserId && showInvoices ? (
                <InvoicesModal
                    heroUserId={heroUserId}
                    externalControls={[showInvoices, setShowInvoices]}
                />
            ) : undefined}
            {heroUserId && showChanges ? (
                <MembershipChangesModal
                    membershipId={membership.id}
                    heroUserId={heroUserId}
                    externalControls={[showChanges, setShowChanges]}
                />
            ) : undefined}

            <Modal
                externalControls={[showUnlockPaymentFailedModal, setShowUnlockPaymentFailedModal]}
                isCancelable
            >
                <Section centered noDefaultPadding>
                    <P centered size="large" strong>
                        Are you sure you want to reset payment failed status?
                    </P>
                    <P centered>
                        This will reactivate the smart dispenser/app and postpone Payment Failed drip.
                        If the payment is not settled before next automatic payment retry, member
                        will be re-added to Payment failed flow.
                    </P>
                    <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
                        <Button
                            disabled={isUnlockPaymentFailedPending}
                            variant="outlineSecondary"
                            onClick={() => {
                                setShowUnlockPaymentFailedModal(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={isUnlockPaymentFailedPending}
                            onClick={() => {
                                if (heroUserId) {
                                    unlockPaymentFailed({ hero_user_id: heroUserId })
                                        .then(() => {
                                            setShowUnlockPaymentFailedModal(false);
                                            fetchMembershipDetails();
                                        })
                                        .catch(() => {});
                                }
                            }}
                        >
                            Confirm
                        </Button>
                    </div>
                    {unlockPaymentFailedError ? (
                        <QueryInlineError centered error={unlockPaymentFailedError} />
                    ) : null}
                </Section>
            </Modal>
        </div>
    ) : null;
};

export default Details;
