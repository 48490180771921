import { DeviceDetails } from '@hero/hero-types';
import { accessTokenSelector } from '@hero/redux-data/auth/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import envVars from '../../../../constants/envVars';
import P from '@hero/ui-kit/typography/P';
import Section from '@hero/ui-kit/layout/Section';
import * as styles from './style.module.css';

interface DeviceWebsocketEventsProps {
    deviceDetails: DeviceDetails;
}

type DeviceEvent = {
    Event: string;
    Info_out_of_sync: boolean;
    actual_datetime: string;
    error_type: string;
    id: string;
    human_readable: string;
    type: number;
    firmware_version: string;
    log?: string;
};

type DispenseEvent = {
    Event: string;
    Info_out_of_sync: boolean;
    actual_datetime: string;
    adherence_event_type: number;
    id: string;
    hero_schedule_id: number;
    odometer: number;
    scheduled_datetime: string;
    log?: string;
    pills: [
        {
            dispensed_since_refill: number;
            error_type: string;
            hero_pill_id: number;
            id: string;
            last_refill: string;
            level: number;
            level_warning: string;
            n_id: string;
            pill_id: string;
            pill_manual_take_required: false;
            pill_qty: number;
            pill_qty_manual: number;
            pill_qty_requested: number;
        }
    ];
    schedules: [
        {
            id: string;
        }
    ];
    firmware_version: string;
};

type PillLevelEvent = {
    pills: [
        {
            Info_out_of_sync: boolean;
            actual_datetime: string;
            error_type: string;
            id: string;
            pill_id: string;
            refill_time: string;
            status: string;
            firmware_version: string;
        }
    ];
    daily_caregiver_flag: false;
    log?: string;
};

type BackofficeLog<T> = {
    type: string;
    payload: {
        device_serial: string;
        reported_at_utc: string;
        device_event_type: 'device_event' | 'dispense_event' | 'pill_level_event';
        event_local_datetime: string;
        event_data: T;
    };
};

const DeviceWebsocketEvents: React.FC<DeviceWebsocketEventsProps> = () => {
    const socketUrl = `${envVars.API_CLOUD_SOCKET}ws/backoffice/`;
    const [messageHistory, setMessageHistory] = React.useState<
        MessageEvent<BackofficeLog<DeviceEvent | DispenseEvent | PillLevelEvent>>[]
    >([]);
    const accessToken = useSelector(accessTokenSelector);

    const { sendJsonMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
        onOpen: () => authenticate()
    });

    const authenticate = () => {
        const authMessage = {
            type: 'request_authorization',
            payload: { authorization_token: `Bearer ${accessToken}` }
        };

        sendJsonMessage(authMessage);
    };

    React.useEffect(() => {
        if (lastMessage !== null) {
            setMessageHistory((prev) => prev.concat(lastMessage));
        }
    }, [lastMessage]);

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated'
    }[readyState];

    const history = messageHistory
        .map((item) => ({
            ...item,
            data: typeof item.data === 'string' ? JSON.parse(item.data) : item.data
        }))
        .filter((message) => message.data?.type === 'backoffice_rt_log');

    return (
        <Section paddingHorizontal="none">
            <div style={{ display: 'flex', alignItems: 'center', columnGap: '3rem' }}>
                <P noDefaultMargin strong>
                    Connection Status: {connectionStatus}
                </P>
                {history.length ? (
                    <P noDefaultMargin strong>
                        Firmware Version: {history[0].data?.payload?.event_data?.firmware_version}
                    </P>
                ) : null}
            </div>

            {history.length ? (
                <>
                    <table id={styles.deviceLogs}>
                        <thead>
                            <tr>
                                <th>
                                    Reported At <br />
                                    (UTC Time)
                                </th>
                                <th>Event Type</th>
                                <th>Event Name</th>
                                <th>Human Readable</th>
                                <th>Log</th>
                            </tr>
                        </thead>
                        <tbody>
                            {history.map((message, index) => {
                                if (message.data.payload.device_event_type === 'device_event') {
                                    const eventData = message.data.payload
                                        .event_data as DeviceEvent;

                                    return (
                                        <tr key={index}>
                                            <td>{message.data.payload.reported_at_utc}</td>
                                            <td>{message.data.payload.device_event_type}</td>
                                            <td>{eventData.Event}</td>
                                            <td>{eventData.human_readable}</td>
                                            <td>{eventData?.log || ''}</td>
                                        </tr>
                                    );
                                }

                                if (message.data.payload.device_event_type === 'dispense_event') {
                                    const eventData = message.data.payload
                                        .event_data as DispenseEvent;

                                    return (
                                        <tr key={index}>
                                            <td>{message.data.payload.reported_at_utc}</td>
                                            <td>{message.data.payload.device_event_type}</td>
                                            <td>{eventData.Event}</td>
                                            <td></td>
                                            <td>{eventData?.log || ''}</td>
                                        </tr>
                                    );
                                }

                                if (message.data.payload.device_event_type === 'pill_level_event') {
                                    const eventData = message.data.payload
                                        .event_data as PillLevelEvent;

                                    return (
                                        <tr key={index}>
                                            <td>{message.data.payload.reported_at_utc}</td>
                                            <td>{message.data.payload.device_event_type}</td>
                                            <td></td>
                                            <td></td>
                                            <td>{eventData?.log}</td>
                                        </tr>
                                    );
                                }
                            })}
                        </tbody>
                    </table>
                </>
            ) : (
                <P>No events found</P>
            )}
        </Section>
    );
};

export default DeviceWebsocketEvents;
