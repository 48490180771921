import React, { CSSProperties, useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Section from '@hero/ui-kit/layout/Section';
import Button from '@hero/ui-kit/inputs/Button';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
import CancellationFlow from '../../../views/CancellationFlow';
import CancellationDetails from './CancellationDetails';
import TabBar from '../TabBar';
import { OrganizationRouteParams } from '../types';
import getDetailViewTitle from '../utils/getDetailViewTitle';
import Divider from '@hero/ui-kit/components/Divider';
import ToBeCancelledFlow from './ToBeCancelledFlow';
import useCancellationRequestDetails from '../DischargedQueueMemberList/api/useCancellationRequestDetails';
import showRTMDischargeFeature from '../utils/showDischargeFeature';
import DefaultLayout from '../../../components/DefaultLayout';
import useGetEnterpriseMemberDetails from '../api/useGetEnterpriseMemberDetails';
import useGetCancellationEnterpriseMembershipDetails from '../api/useGetCancellationEnterpriseMembershipDetails';
import useGetEnterpriseCancellationOptions from '../api/useGetEnterpriseCancellationOptions';
import useGetUserDeviceDetails from '../../Devices/api/useGetUserDeviceDetails';
import InlineLoader from '../../../components/InlineLoader';
import useGetRmaEnums from '../../Rma/api/useGetRmaEnums';
import useGetDischargedMember from '../../Retention/api/useGetDischargedMember';

const ToBeCancelledMessage: React.FC<{ memberCancellationId: string }> = ({
    memberCancellationId
}) => {
    const { data } = useCancellationRequestDetails({ memberCancellationId });

    return (
        <P noDefaultMargin>
            {`Cancellation request with reason ${data?.data.discharge_reason} was sent to provider on ${data?.data.date_discharge_requested}`}
        </P>
    );
};

const EnterpriseMembershipCancellation: React.FC = () => {
    const { id: organization_id, enrollment_id: id } =
        useParams<Required<OrganizationRouteParams>>();
    const navigate = useNavigate();

    const {
        data: enrollmentResponse,
        refetch: refetchEnrollment,
        isLoading: isFetchingEnrollment,
        isError: isGetEnterpriseMemberError
    } = useGetEnterpriseMemberDetails({
        id: id ? +id : 0
    });

    const enrollment = enrollmentResponse?.data;
    const memberCancellationId = enrollment?.membership_cancellation_request_id;
    const isCancelled = enrollment?.member?.status === 'CANCELLED';
    const heroUserId = enrollment?.member?.hero_user_id;

    const memberId = !isGetEnterpriseMemberError && enrollment ? enrollment.member?.id : undefined;

    const { data: dischargedMemberResponse, isLoading: isFetchingDischargedMember } =
        useGetDischargedMember({ member_id: memberId ? memberId : 0 }, !!memberId);

    const {
        data: orgCancelDetailsResponse,
        isLoading: isFetchingCancelDetails,
        refetch: refetchOrgCancelDetails
    } = useGetCancellationEnterpriseMembershipDetails({ id: id ? +id : 0 }, isCancelled);

    const { data: orgCancellationOptionsResponse, isPending: isFetchingOrgCancelOptions } =
        useGetEnterpriseCancellationOptions({
            id: id ? +id : 0
        });

    const { data: rmaEnumsResponse, isLoading: isFetchingRmaEnums } = useGetRmaEnums(true);

    const {
        mutate,
        data: userDeviceResponse,
        isPending: isFetchingUserDevice
    } = useGetUserDeviceDetails();

    useEffect(() => {
        if (heroUserId) {
            mutate({ user_id: heroUserId });
        }
    }, [heroUserId, mutate]);

    const handleRefetchOrgCancelDetails = () => {
        refetchOrgCancelDetails();
    };

    const orgCancellationDetails = orgCancelDetailsResponse?.data;
    const orgCancellationOptions = orgCancellationOptionsResponse?.data;
    const userDetails = userDeviceResponse?.data;
    const rmaEnums = rmaEnumsResponse?.data;

    const isFetching =
        isFetchingEnrollment ||
        isFetchingOrgCancelOptions ||
        isFetchingUserDevice ||
        isFetchingCancelDetails ||
        isFetchingRmaEnums ||
        isFetchingDischargedMember;

    const showDischargedWarning =
        dischargedMemberResponse?.data.is_discharged === false &&
        ['ACTIVATED', 'ENROLLED'].includes(enrollment?.member?.status || '');

    const getEnrollmentDetails = React.useCallback(() => {
        refetchEnrollment();
    }, []);

    const handleBackToList = useCallback(() => {
        navigate(`/organizations/${organization_id}/members`, { replace: true });
    }, [navigate, organization_id]);

    const innerWrapPadding = useComponentPadding({ paddingVertical: 'large' });

    const headerWrapStyles: CSSProperties = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    };

    const [openCancellationFlow, setOpenCancellationFlow] = useState(false);
    const handleOpenCancellationFlow = useCallback(() => {
        setOpenCancellationFlow(true);
    }, []);

    const [openToBeCancelledFlow, setOpenToBeCancelledFlow] = useState(false);
    const handleOpenToBeCancelledFlow = useCallback(() => {
        setOpenToBeCancelledFlow(true);
    }, []);

    return (
        <DefaultLayout>
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <div style={headerWrapStyles}>
                    <H role="h5">{getDetailViewTitle(enrollment)}</H>
                    <Button variant="secondary" onClick={handleBackToList} noDefaultMargin>
                        Back To List
                    </Button>
                </div>
                <TabBar />
                <div style={innerWrapPadding}>
                    {isFetching ? (
                        <InlineLoader />
                    ) : !enrollment ? (
                        <P>No membership data available.</P>
                    ) : isCancelled ? (
                        <CancellationDetails
                            orgCancellationDetails={orgCancellationDetails}
                            orgCancellationOptions={orgCancellationOptions}
                            enrollment={enrollment}
                            onRefetchOrgCancelDetails={handleRefetchOrgCancelDetails}
                            rmaEnums={rmaEnums}
                        />
                    ) : (
                        <>
                            {showRTMDischargeFeature ? (
                                <>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '2rem'
                                        }}
                                    >
                                        <Button
                                            size="small"
                                            onClick={handleOpenToBeCancelledFlow}
                                            disabled={typeof memberCancellationId === 'string'}
                                        >
                                            To Be Cancelled
                                        </Button>
                                        {memberCancellationId ? (
                                            <ToBeCancelledMessage
                                                memberCancellationId={memberCancellationId}
                                            />
                                        ) : null}
                                    </div>
                                    <Divider />
                                </>
                            ) : null}

                            <Button size="small" onClick={handleOpenCancellationFlow}>
                                Cancel
                            </Button>
                        </>
                    )}
                </div>
            </Section>
            {openCancellationFlow ? (
                <CancellationFlow
                    isOrganizationMember
                    externalControls={[openCancellationFlow, setOpenCancellationFlow]}
                    orgCancellationDetails={orgCancellationDetails}
                    orgCancellationOptions={orgCancellationOptions}
                    enterpriseEnrollment={enrollment}
                    userDetails={userDetails}
                    rmaEnums={rmaEnums}
                    showDischargedWarning={showDischargedWarning}
                />
            ) : null}
            {openToBeCancelledFlow ? (
                <ToBeCancelledFlow
                    onCompleteCallback={getEnrollmentDetails}
                    externalControls={[openToBeCancelledFlow, setOpenToBeCancelledFlow]}
                />
            ) : null}
        </DefaultLayout>
    );
};

export default EnterpriseMembershipCancellation;
