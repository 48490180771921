import React, { CSSProperties, useState, useEffect, useCallback } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import Section from '@hero/ui-kit/layout/Section';
import Button from '@hero/ui-kit/inputs/Button';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
import getURLParams from '@hero/hero-utils/URLSearchParams';

import InlineLoader from '../../../components/InlineLoader';
import CancellationFlow from '../../../views/CancellationFlow';
import MembershipCancellationDetails from './CancellationDetails';
import TabBar from '../TabBar';
import { MembershipRouteParams } from '../types';
import DefaultLayout from '../../../components/DefaultLayout';
import useGetMembershipDetails from '../api/useGetMembershipDetails';
import useGetRmaEnums from '../../Rma/api/useGetRmaEnums';
import useGetUserDeviceDetails from '../../Devices/api/useGetUserDeviceDetails';
import useGetCancellationOptions from '../api/useGetCancellationOptions';
import useGetCancellationDetails from '../api/useGetCancellationDetails';
import useGetDetailViewTitleV2 from '../utils/useGetDetailViewTitleV2';
import QueryInlineError from '../../../components/QueryInlineError';

const MembershipCancellation: React.FC = () => {
    const { id } = useParams<MembershipRouteParams>();
    const { search } = useLocation();
    const navigate = useNavigate();

    const serial = getURLParams(search, 'serial');
    const userId = getURLParams(search, 'user_id');

    const shouldFetchMembership = !!id || !!userId || !!serial;

    const membershipParams = {
        ...(id && { id: +id }),
        ...(userId && { hero_user_id: +userId }),
        ...(serial && { device_serial: serial })
    };

    const {
        data: membershipResponse,
        isLoading: isFetchingMembership,
        refetch: refetchMembership,
        error: membershipError
    } = useGetMembershipDetails(membershipParams, shouldFetchMembership);

    const { data: rmaEnumsResponse, isLoading: isFetchingRmaEnums } = useGetRmaEnums(true);

    const {
        mutate: getUserDeviceDetails,
        data: userDeviceResponse,
        isPending: isFetchingUserDevice,
        error: userDeviceError
    } = useGetUserDeviceDetails();

    const userDetails = userDeviceResponse?.data;
    const rmaEnums = rmaEnumsResponse?.data;
    const membership = membershipResponse?.data.data;

    const { data: cancellationOptionsResponse, isLoading: isFetchingCancellationOptions } =
        useGetCancellationOptions(
            membershipParams,
            !isFetchingMembership && membership && membership?.status !== 'cancelled'
        );

    const cancellationOptions = cancellationOptionsResponse?.data.data;

    const {
        data: cancellationDetailsResponse,
        isLoading: isCancellationDetailsLoading,
        refetch: refetchCancellationDetails
    } = useGetCancellationDetails(membershipParams, membership?.status === 'cancelled');

    const cancellationDetails = cancellationDetailsResponse?.data.data;

    const isFetching =
        isCancellationDetailsLoading ||
        isFetchingUserDevice ||
        isFetchingMembership ||
        isFetchingRmaEnums ||
        isFetchingCancellationOptions;

    useEffect(() => {
        if (membership?.hero_user_id) {
            getUserDeviceDetails({ user_id: +membership.hero_user_id });
        } else if (!membership?.hero_user_id && membership?.serial) {
            getUserDeviceDetails({ external_serial: membership.serial });
        }
    }, [membership, getUserDeviceDetails]);

    useEffect(() => {
        if (!id && membership?.id) {
            navigate(`/membership/${membership.id}/cancellation`, { replace: true });
        }
    }, [navigate, id, membership]);

    const handleRefetchCancellationDetails = () => {
        refetchCancellationDetails();
    };

    const handleCompleteCallback = () => {
        refetchMembership();
        refetchCancellationDetails();
    };

    const headerTitle = useGetDetailViewTitleV2(userDetails, membership);

    const handleBackToList = useCallback(() => {
        navigate('/membership', { replace: true });
    }, [navigate]);

    const innerWrapPadding = useComponentPadding({ paddingVertical: 'large' });

    const headerWrapStyles: CSSProperties = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    };

    const [openCancellationFlow, setOpenCancellationFlow] = useState(false);
    const handleOpenCancellationFlow = useCallback(() => {
        setOpenCancellationFlow(true);
    }, []);

    return (
        <DefaultLayout>
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <div style={headerWrapStyles}>
                    <H role="h5">{headerTitle}</H>
                    <Button variant="secondary" onClick={handleBackToList} noDefaultMargin>
                        Back To List
                    </Button>
                </div>
                <TabBar />
                <div style={innerWrapPadding}>
                    {isFetching ? (
                        <InlineLoader />
                    ) : !membership ? (
                        <P>No membership data available.</P>
                    ) : membershipError || userDeviceError ? (
                        <>
                            {membershipError ? (
                                <>
                                    <P centered strong>
                                        Fetch Membership Details Error:
                                    </P>
                                    <QueryInlineError error={membershipError} centered />
                                </>
                            ) : null}
                            {userDeviceError ? (
                                <>
                                    <P centered strong>
                                        Fetch User and Device Details Error:
                                    </P>
                                    <QueryInlineError error={userDeviceError} centered />
                                </>
                            ) : null}
                        </>
                    ) : membership.status === 'cancelled' && cancellationDetails ? (
                        <MembershipCancellationDetails
                            membership={membership}
                            rmaEnums={rmaEnums}
                            cancellationDetails={cancellationDetails}
                            cancellationOptions={cancellationOptions}
                            onRefetchCancellationDetails={handleRefetchCancellationDetails}
                            userDetails={userDetails}
                        />
                    ) : (
                        <Button size="small" onClick={handleOpenCancellationFlow}>
                            Cancel
                        </Button>
                    )}
                </div>
            </Section>
            {openCancellationFlow ? (
                <CancellationFlow
                    externalControls={[openCancellationFlow, setOpenCancellationFlow]}
                    membership={membership}
                    userDetails={userDetails}
                    rmaEnums={rmaEnums}
                    cancellationOptions={cancellationOptions}
                    cancellationDetails={cancellationDetails}
                    onCompleteCallback={handleCompleteCallback}
                />
            ) : null}
        </DefaultLayout>
    );
};

export default MembershipCancellation;
