import React, { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { CancellationType } from '@hero/hero-types';
import Form from '@hero/ui-kit/inputs/Form';
import cancelMembershipSchema from '@hero/validators/forms/backoffice/cancelMembershipSchema';

import InlineLoader from '../../components/InlineLoader';
import InlineError from '../../components/InlineError';
import { useCancellationStateMachine } from './context/cancellationStateMachine';
import CancellationFormFields from '../forms/CancellationFormFields';
import {
    isMitigationListLoadingSelector,
    mitigationListErrorSelector
} from '@hero/redux-data/backoffice/mitigationList/selectors';

const StepDetails: React.FC = () => {
    const {
        isOrganizationMember,
        handleTypeSelect,
        handleDetailsNext,
        mitigationDetails,
        cancellationOptions,
        orgCancellationOptions,
        enrollment
    } = useCancellationStateMachine();

    const isFetchingMitigationList = useSelector(isMitigationListLoadingSelector, shallowEqual);

    const { error: mitigationListError, errorMessage: mitigationListErrorMessage } = useSelector(
        mitigationListErrorSelector,
        shallowEqual
    );

    const isLoading = isFetchingMitigationList;

    const types: CancellationType[] = useMemo(
        () =>
            (isOrganizationMember
                ? orgCancellationOptions?.cancellation_options.cancellation_types
                : cancellationOptions?.cancellation_types) ?? [],
        [isOrganizationMember, cancellationOptions, orgCancellationOptions]
    );

    const defaultCancellationType = types.length > 0 ? types[0].code : undefined;

    const defaultValues = useMemo(
        () => ({
            cancellation_type: defaultCancellationType,
            ...(!mitigationDetails.showMitigation && { isCancellationProblemResolved: false })
        }),
        [defaultCancellationType, mitigationDetails.showMitigation]
    );

    return isLoading ? (
        <InlineLoader />
    ) : (
        <Form
            validationSchema={cancelMembershipSchema}
            defaultValues={defaultValues}
            submitFn={handleDetailsNext}
        >
            <CancellationFormFields
                enterpriseEnrollment={enrollment}
                cancellationOptions={
                    cancellationOptions || orgCancellationOptions?.cancellation_options
                }
                showEncourageHero={mitigationDetails.showMitigation}
                onTypeSelect={handleTypeSelect}
                isOrganizationMember={isOrganizationMember}
            />
            {/* {feeError && (
                <InlineError
                    centered
                    message={feeErrorMessage || 'Error calculating cancellation fee.'}
                />
            )}
            {refundError && (
                <InlineError centered message={refundErrorMessage || 'Error calculating refund.'} />
            )} */}
            {mitigationListError && (
                <InlineError
                    centered
                    message={mitigationListErrorMessage || 'Error fetching mitigation list.'}
                />
            )}
        </Form>
    );
};

export default StepDetails;
