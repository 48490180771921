import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { userDrips } from './queryKeys';
import envVars from '../../../constants/envVars';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';
const useGetUserDrips = (props, enabled) => {
    const additional = useQueryRequestParams();
    return useQuery({
        queryFn: () => axios.get(`${envVars.API_COMMERCE_HOSTNAME}backoffice/email/drip-subscription`, {
            ...additional,
            params: { ...props }
        }),
        queryKey: [userDrips, props.email],
        enabled
    });
};
export default useGetUserDrips;
