import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { deviceWebsocketsLogs } from './queryKeys';
import envVars from '../../../../constants/envVars';
import useQueryRequestParams from '../../../../hooks/useQueryRequestParams';
const useGetDeviceLogSubscribe = (props, enabled) => {
    const additional = useQueryRequestParams();
    return useQuery({
        queryFn: () => axios.get(`${envVars.API_CLOUD_HOSTNAME}backoffice/real-time-log`, {
            ...additional,
            params: { ...props }
        }),
        queryKey: [deviceWebsocketsLogs, props.serial],
        enabled,
        retry: false,
        refetchOnWindowFocus: false
    });
};
export default useGetDeviceLogSubscribe;
