import * as React from 'react';
import Container from '@hero/ui-kit/layout/Container';
import P from '@hero/ui-kit/typography/P';
import H from '@hero/ui-kit/typography/H';
import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import Section from '@hero/ui-kit/layout/Section';
import Button from '@hero/ui-kit/inputs/Button';
import useRmaEnums from '../utils/useRmaEnums';
import { UpdateRmaRequestParams } from '@hero/validators/forms/backoffice/rmaRequestSchema';
import InlineLoader from '../../../components/InlineLoader';
import { AxiosError } from 'axios';
import QueryInlineError from '../../../components/QueryInlineError';
import { RmaEnums } from '@hero/hero-types';

type RmaUpdateConfirmModalProps = {
    formValues: UpdateRmaRequestParams;
    isExchangeRma?: boolean;
    onConfirm?: () => void;
    isChangingRmaRequest?: boolean;
    isRmaRequestSuccess?: boolean;
    errorUpdateRmaRequest: AxiosError<unknown, any> | null;
    onSuccess?: () => void;
    rmaEnums?: RmaEnums;
} & Pick<ModalProps, 'externalControls'>;

const RmaUpdateConfirmModal: React.FC<RmaUpdateConfirmModalProps> = ({
    onConfirm,
    formValues,
    isExchangeRma = false,
    isChangingRmaRequest = false,
    isRmaRequestSuccess = false,
    onSuccess,
    externalControls,
    errorUpdateRmaRequest,
    rmaEnums
}) => {
    const [, setExternalState] = externalControls ?? [];
    const { returnStatuses, returnLocations, rmaTypes, rmaStatuses } = useRmaEnums(rmaEnums);

    const close = React.useCallback(() => {
        setExternalState && setExternalState(false);
    }, [setExternalState]);

    const {
        rma_type,
        rma_status,
        exchange_device_serial,
        exchange_tracking_number,
        return_location,
        return_status,
        return_tracking_number,
        send_box,
        address_line_1,
        address_line_2,
        city,
        state,
        zip,
        comments
    } = formValues;

    return (
        <Modal type="warn" isCancelable externalControls={externalControls}>
            {isRmaRequestSuccess ? (
                <>
                    <H role="h4" centered>
                        RMA Update Success
                    </H>
                    <P size="large">RMA Updated.</P>
                    <Section styles={{ marginTop: '1.2rem' }} noDefaultPadding centered>
                        <Button width="large" onClick={onSuccess}>
                            Ok
                        </Button>
                    </Section>
                </>
            ) : (
                <>
                    <H role="h4" centered>
                        RMA Update Confirmation
                    </H>
                    <Container
                        gridTemplateColumns="1fr 1fr"
                        gridColumnGap="regular"
                        styles={{ width: '100%' }}
                    >
                        <P strong>RMA Type</P>
                        <P>{rma_type && rmaTypes ? rmaTypes[rma_type] : '-'}</P>
                        <P strong>RMA Status</P>
                        <P>{rma_status && rmaStatuses ? rmaStatuses[rma_status] : '-'}</P>
                        {isExchangeRma ? (
                            <>
                                <P strong># Exchange Serial</P>
                                <P>{exchange_device_serial || '-'}</P>
                                <P strong># Exchange Tracking</P>
                                <P>{exchange_tracking_number || '-'}</P>
                            </>
                        ) : null}
                        <P strong>Return Location</P>
                        <P>
                            {return_location && returnLocations
                                ? returnLocations[return_location]
                                : '-'}
                        </P>
                        <P strong>Return Status</P>
                        <P>
                            {return_status && returnStatuses ? returnStatuses[return_status] : '-'}
                        </P>
                        <P strong># Return Tracking Number</P>
                        <P>{return_tracking_number || '-'}</P>
                        <P strong>Send box</P>
                        <P>{send_box ? 'Yes' : 'No'}</P>
                        <P strong>Street Address</P>
                        <P>{address_line_1 || '-'}</P>
                        <P strong>Street Address 2 (Optional)</P>
                        <P>{address_line_2 || '-'}</P>
                        <P strong>City</P>
                        <P>{city || '-'}</P>
                        <P strong>State</P>
                        <P>{state || '-'}</P>
                        <P strong>Zip</P>
                        <P>{zip || '-'}</P>
                        <P strong>Notes</P>
                        <P>{comments || '-'}</P>
                    </Container>
                    <Section styles={{ marginTop: '1.2rem' }} noDefaultPadding centered>
                        {isChangingRmaRequest ? (
                            <InlineLoader />
                        ) : (
                            <>
                                <Button
                                    width="large"
                                    onClick={onConfirm}
                                    disabled={isChangingRmaRequest}
                                >
                                    Confirm
                                </Button>
                                <Button
                                    width="large"
                                    variant="secondary"
                                    onClick={close}
                                    disabled={isChangingRmaRequest}
                                >
                                    Cancel
                                </Button>
                            </>
                        )}
                    </Section>
                    {errorUpdateRmaRequest ? (
                        <QueryInlineError centered error={errorUpdateRmaRequest} />
                    ) : null}
                </>
            )}
        </Modal>
    );
};

export default RmaUpdateConfirmModal;
