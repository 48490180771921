import React, { useMemo, FC } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import {
    isDeviceRestockingCheckLoadingSelector,
    deviceRestockingCheckSelector
} from '@hero/redux-data/backoffice/deviceRestockingCheck/selectors';
import useEnumOptions from '../utils/useEnumOptions';
import { returnLocationsSelector } from '@hero/redux-data/backoffice/rmaEnums/selectors';
import DefaultSelect from '@hero/ui-kit/inputs/DefaultSelect';

interface ReturnLocationSelectProps {
    onChange?: (selectedValue: string) => void;
    returnLocations?: Record<string, string>;
}

const ReturnLocationSelect: FC<ReturnLocationSelectProps> = ({
    onChange,
    returnLocations: defaultReturnLocations
}) => {
    const returnLocations =
        defaultReturnLocations || useSelector(returnLocationsSelector, shallowEqual);
    const returnLocationOptions = useEnumOptions(returnLocations);
    const isDeviceRestockLoading = useSelector(
        isDeviceRestockingCheckLoadingSelector,
        shallowEqual
    );

    const deviceRestockingCheck = useSelector(deviceRestockingCheckSelector, shallowEqual);
    const { eligible_for_restock = 'unknown' } = deviceRestockingCheck ?? {};

    const isReconnextEnabled = !!returnLocationOptions.find(([value]) => value === '7');

    const restockStatus = useMemo(() => {
        const label = isReconnextEnabled ? 'Reconext' : 'Rush Order';

        if (isDeviceRestockLoading) {
            return 'Checking...';
        }
        if (eligible_for_restock === 'yes') {
            return `Pass - Return to ${label}`;
        }

        if (eligible_for_restock === 'no') {
            return `Fail - Return to ${label}`;
        }

        return `Uknown - Return to ${label}`;
    }, [eligible_for_restock, isDeviceRestockLoading, isReconnextEnabled]);

    const filteredReturnLocationOptions = useMemo(() => {
        return returnLocationOptions;
    }, [eligible_for_restock, returnLocationOptions]);

    const handleChange = (event: React.FormEvent<HTMLSelectElement>) => {
        const value = event.currentTarget.value;
        onChange && onChange(value);
    };

    return (
        <>
            <DefaultSelect
                onChange={handleChange}
                name="return_location"
                displayName={`Return Location (status: ${restockStatus})`}
                options={[
                    { label: 'Choose Location', value: '' },
                    ...filteredReturnLocationOptions.map(([value, label]) => ({ label, value }))
                ]}
            />
        </>
    );
};

export default ReturnLocationSelect;
