import Button from '@hero/ui-kit/inputs/Button';
import Section from '@hero/ui-kit/layout/Section';
import * as React from 'react';
import { useCancellationStateMachine } from './context/cancellationStateMachine';
import P from '@hero/ui-kit/typography/P';
import useTheme from '@hero/branding/theme';

interface StepDischargeWarningProps {}

const StepDischargeWarning: React.FC<StepDischargeWarningProps> = () => {
    const { handleContinueCancellation } = useCancellationStateMachine();

    const theme = useTheme();
    const heroPrimary = theme.colors.brand.primary.alpha;

    return (
        <Section centered noDefaultPadding>
            <div
                style={{
                    border: `6px solid ${heroPrimary}`,
                    borderRadius: '20px',
                    marginBottom: '12px'
                }}
            >
                <div
                    style={{
                        width: '100%',
                        textAlign: 'center',
                        color: 'white',
                        background: heroPrimary,
                        fontSize: '24px',
                        fontWeight: 'bold',
                        paddingTop: '6px',
                        paddingBottom: '10px'
                    }}
                >
                    Warning!
                </div>

                <P centered styles={{ fontSize: '20px', padding: '30px' }} noDefaultMargin>
                    RTM provider must complete patient discharge before membership is cancelled.{' '}
                    <br />
                    Please escalate to provider for next steps.
                </P>
            </div>

            <Button variant="secondary" onClick={handleContinueCancellation}>
                Continue Cancellation
            </Button>
        </Section>
    );
};

export default StepDischargeWarning;
