import React, { useCallback } from 'react';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';

import Button from '@hero/ui-kit/inputs/Button';

type Props = {
    predicate: boolean;
    handleToggle: (state: boolean) => void;
    yesLabel?: string;
    noLabel?: string;
};

const YesNoOptions: React.FC<Props> = ({
    predicate,
    handleToggle,
    yesLabel = 'Yes',
    noLabel = 'No'
}) => {
    const yesButtonMargin = useComponentMargin({ marginVertical: 'none' });
    const noButtonMargin = useComponentMargin({ marginLeft: 'small', marginVertical: 'none' });
    const handleYesClick = useCallback(() => handleToggle(true), [handleToggle]);
    const handleNoClick = useCallback(() => handleToggle(false), [handleToggle]);

    return (
        <div>
            <Button
                onClick={handleYesClick}
                variant={predicate ? 'primary' : 'outline'}
                size="small"
                styles={yesButtonMargin}
            >
                {yesLabel}
            </Button>
            <Button
                onClick={handleNoClick}
                variant={!predicate ? 'primary' : 'outline'}
                size="small"
                styles={noButtonMargin}
            >
                {noLabel}
            </Button>
        </div>
    );
};

export default YesNoOptions;
