import React from 'react';
import Section from '@hero/ui-kit/layout/Section';
import Button from '@hero/ui-kit/inputs/Button';

interface ConfirmSectionProps {
    buttonLabel?: string;
}

const ConfirmSection: React.FC<ConfirmSectionProps> = ({ buttonLabel = 'Complete RMA' }) => {
    return (
        <Section centered noDefaultPadding marginTop="regular">
            <Button width="large" type="submit">
                {buttonLabel}
            </Button>
        </Section>
    );
};

export default ConfirmSection;
