import Button from '@hero/ui-kit/inputs/Button';
import P from '@hero/ui-kit/typography/P';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getUserDeviceDetails } from '@hero/redux-data/backoffice/userDeviceDetails/actionCreators';
import QueryInlineError from '../../../../components/QueryInlineError';
import { BackofficeUserDeviceDetails } from '@hero/hero-types';
import useChangeUserMailingList from '../../api/useChangeUserMailingList';
import useGetUserDrips from '../../api/useGetUserDrips';
import useUpdateUserDrips from '../../api/useUpdateUserDrips';

interface MailingListChangeProps {
    data: BackofficeUserDeviceDetails | null;
}

const MailingListChange: React.FC<MailingListChangeProps> = ({ data }) => {
    const email = data?.user?.email;

    const { data: userDrips, isFetching: isDripsFetching } = useGetUserDrips(
        { email: email || '' },
        !!email
    );
    const { mutateAsync: updateUserDrip, isPending: isUpdateUserDripPending } =
        useUpdateUserDrips();

    const {
        mutateAsync: changeUserMailingList,
        error,
        isPending
    } = useChangeUserMailingList(data?.user?.id || 0);

    const dispatch = useDispatch();
    const { user_id } = useParams();

    const handleSuccess = () => {
        user_id && dispatch(getUserDeviceDetails({ user_id: +user_id }));
    };

    const preparedData = React.useMemo(() => {
        const account_id = data?.device?.account_id;
        const isOwner = !!data?.user?.is_owner;
        if (isOwner && account_id) {
            const isWeeklyNewsletterUnsubscribed = !!data?.user?.owner_unsubscribed;
            const isHeroDisconnectedUnsubscribed = !!data?.user?.hero_disconnected_unsubscribed;

            return {
                isWeeklyNewsletterUnsubscribed,
                labelWeeklyNewsletter: isWeeklyNewsletterUnsubscribed ? 'Subscribe' : 'Unsubscribe',
                isHeroDisconnectedUnsubscribed,
                labelHeroDisconnected: isHeroDisconnectedUnsubscribed ? 'Subscribe' : 'Unsubscribe',
                email,
                account_id
            };
        } else {
            const caregivers = data?.user?.caregiver_accounts || [];
            const userId = data?.user?.id;

            const caregiver = caregivers.find((item) => item.caregiver_user_id === userId);

            const isWeeklyNewsletterUnsubscribed = !!caregiver?.weekly_newsletter_unsubscribed;
            const isHeroDisconnectedUnsubscribed = !!caregiver?.hero_disconnected_unsubscribed;

            return {
                isWeeklyNewsletterUnsubscribed,
                labelWeeklyNewsletter: isWeeklyNewsletterUnsubscribed ? 'Subscribe' : 'Unsubscribe',
                isHeroDisconnectedUnsubscribed,
                labelHeroDisconnected: isHeroDisconnectedUnsubscribed ? 'Subscribe' : 'Unsubscribe',
                email
            };
        }
    }, [data, email]);

    const rmaDripData = React.useMemo(() => {
        const rmaDrip = userDrips?.data?.data.drips.find(
            (item) => item.drip_type === 'RMA_DRIP' && item.email === email
        );

        if (rmaDrip) {
            return {
                isRmaDripUnsubscribed: rmaDrip.unsubscribed
            };
        }

        return {
            isRmaDripUnsubscribed: false
        };
    }, [email, userDrips]);

    const handleToggleWeeklyNewsletter = () => {
        if (email) {
            const data = {
                email,
                mailing_list: 'weekly_newsletter' as const,
                ...(preparedData.account_id && { account_id: preparedData.account_id }),
                unsubscribe: !preparedData.isWeeklyNewsletterUnsubscribed
            };

            changeUserMailingList(data).then(handleSuccess);
        }
    };
    const handleToggleHeroOffline = () => {
        if (email) {
            const data = {
                email,
                mailing_list: 'hero_disconnected' as const,
                ...(preparedData.account_id && { account_id: preparedData.account_id }),
                unsubscribe: !preparedData.isHeroDisconnectedUnsubscribed
            };

            changeUserMailingList(data).then(handleSuccess);
        }
    };

    const handleToggleRmaDrip = () => {
        if (email) {
            updateUserDrip({
                email,
                drip: 1,
                unsubscribe: !rmaDripData.isRmaDripUnsubscribed
            }).then(handleSuccess);
        }
    };

    return (
        <div style={{ width: '100%' }}>
            <P>Email Notifications</P>

            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'space-between',
                    marginBottom: '2.4rem'
                }}
            >
                <P noDefaultMargin>Weekly Newsletter</P>
                <div>
                    <Button
                        size="small"
                        variant={
                            !preparedData.isWeeklyNewsletterUnsubscribed ? 'primary' : 'outline'
                        }
                        disabled={isPending}
                        noDefaultMargin
                        onClick={handleToggleWeeklyNewsletter}
                        styles={{
                            marginRight: '1.2rem',
                            ...(!preparedData.isWeeklyNewsletterUnsubscribed && {
                                pointerEvents: 'none'
                            })
                        }}
                    >
                        Yes
                    </Button>
                    <Button
                        size="small"
                        variant={
                            preparedData.isWeeklyNewsletterUnsubscribed ? 'primary' : 'outline'
                        }
                        disabled={isPending}
                        noDefaultMargin
                        onClick={handleToggleWeeklyNewsletter}
                        styles={{
                            ...(preparedData.isWeeklyNewsletterUnsubscribed && {
                                pointerEvents: 'none'
                            })
                        }}
                    >
                        No
                    </Button>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'space-between'
                }}
            >
                <P noDefaultMargin>Hero Offline</P>
                <div>
                    <Button
                        size="small"
                        variant={
                            !preparedData.isHeroDisconnectedUnsubscribed ? 'primary' : 'outline'
                        }
                        noDefaultMargin
                        disabled={isPending}
                        onClick={handleToggleHeroOffline}
                        styles={{
                            marginRight: '1.2rem',
                            ...(!preparedData.isHeroDisconnectedUnsubscribed && {
                                pointerEvents: 'none'
                            })
                        }}
                    >
                        Yes
                    </Button>
                    <Button
                        size="small"
                        variant={
                            preparedData.isHeroDisconnectedUnsubscribed ? 'primary' : 'outline'
                        }
                        noDefaultMargin
                        disabled={isPending}
                        onClick={handleToggleHeroOffline}
                        styles={{
                            ...(preparedData.isHeroDisconnectedUnsubscribed && {
                                pointerEvents: 'none'
                            })
                        }}
                    >
                        No
                    </Button>
                </div>
            </div>

            {error ? <QueryInlineError error={error} /> : null}

            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'space-between',
                    marginTop: '2.4rem'
                }}
            >
                <P noDefaultMargin>RMA Drips</P>
                {isDripsFetching ? (
                    <div>Loading...</div>
                ) : (
                    <div>
                        <Button
                            size="small"
                            variant={!rmaDripData.isRmaDripUnsubscribed ? 'primary' : 'outline'}
                            disabled={isUpdateUserDripPending}
                            noDefaultMargin
                            onClick={handleToggleRmaDrip}
                            styles={{
                                marginRight: '1.2rem',
                                ...(!rmaDripData.isRmaDripUnsubscribed && {
                                    pointerEvents: 'none'
                                })
                            }}
                        >
                            Yes
                        </Button>
                        <Button
                            size="small"
                            variant={rmaDripData.isRmaDripUnsubscribed ? 'primary' : 'outline'}
                            disabled={isUpdateUserDripPending}
                            noDefaultMargin
                            onClick={handleToggleRmaDrip}
                            styles={{
                                ...(rmaDripData.isRmaDripUnsubscribed && {
                                    pointerEvents: 'none'
                                })
                            }}
                        >
                            No
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MailingListChange;
