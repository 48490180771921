import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { userDrips } from './queryKeys';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';
import envVars from '../../../constants/envVars';
const useUpdateUserDrips = () => {
    const queryClient = useQueryClient();
    const params = useQueryRequestParams();
    return useMutation({
        mutationFn: (attributes) => axios.post(`${envVars.API_COMMERCE_HOSTNAME}backoffice/email/drip-subscription`, { ...attributes }, params),
        onSuccess: (_, vars) => {
            queryClient.invalidateQueries({ queryKey: [userDrips, vars.email] });
        },
        onError: (error) => {
            return error;
        }
    });
};
export default useUpdateUserDrips;
