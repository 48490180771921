// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#nSO9vtlhD7S9GXoOaQi2 {
    border-collapse: collapse;
    width: 100%;
    font-family: Gilroy, Monserrat, "Open Sans", Arial, Helvetica, sans-serif;
}
  
#nSO9vtlhD7S9GXoOaQi2 td, #nSO9vtlhD7S9GXoOaQi2 th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 16px;
}
  
#nSO9vtlhD7S9GXoOaQi2 tr:hover {
    background-color: whitesmoke;
}
  
#nSO9vtlhD7S9GXoOaQi2 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #d05221;
    color: white;
}


`, "",{"version":3,"sources":["webpack://./pages/Devices/DeviceDetails/DeviceWebsocketLogs/style.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,yEAAyE;AAC7E;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,gBAAgB;IAChB,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":["#deviceLogs {\n    border-collapse: collapse;\n    width: 100%;\n    font-family: Gilroy, Monserrat, \"Open Sans\", Arial, Helvetica, sans-serif;\n}\n  \n#deviceLogs td, #deviceLogs th {\n    border: 1px solid #ddd;\n    padding: 8px;\n    font-size: 16px;\n}\n  \n#deviceLogs tr:hover {\n    background-color: whitesmoke;\n}\n  \n#deviceLogs th {\n    padding-top: 12px;\n    padding-bottom: 12px;\n    text-align: left;\n    background-color: #d05221;\n    color: white;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
export var deviceLogs = `nSO9vtlhD7S9GXoOaQi2`;
export default ___CSS_LOADER_EXPORT___;
