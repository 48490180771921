import React, { CSSProperties, useCallback, useMemo } from 'react';

import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
import useSectionPadding from '@hero/ui-kit/hooks/useSectionPadding';

import {
    CancellationStateMachineProvider,
    useCancellationStateMachine
} from './context/cancellationStateMachine';
import ModalFlowHeader from '../../components/ModalFlowHeader';
import InlineLoader from '../../components/InlineLoader';
import ModalSteps from './ModalSteps';
import {
    BackofficeMembershipDetails,
    BackofficeUserDeviceDetails,
    CancellationDetails,
    CancellationOptions,
    EnterpriseCancellationDetails,
    EnterpriseCancellationOptions,
    EnterpriseEnrollment,
    RmaEnums,
    RmaRequest
} from '@hero/hero-types';
import CancellationBanner from './CancellationBanner';

type Props = {
    isOrganizationMember?: boolean;
    isCreateNewRma?: boolean;
    isLoading?: boolean;
    disableCreateRma?: boolean;
    showDischargedWarning?: boolean;
    onCompleteCallback?: () => void;
    onCloseModal?: () => void;
    membership?: BackofficeMembershipDetails;
    userDetails?: BackofficeUserDeviceDetails;
    rmaEnums?: RmaEnums;
    cancellationDetails?: CancellationDetails;
    cancellationOptions?: CancellationOptions;
    enterpriseEnrollment?: EnterpriseEnrollment;
    orgCancellationDetails?: EnterpriseCancellationDetails;
    orgCancellationOptions?: EnterpriseCancellationOptions;
    rma?: RmaRequest;
} & Pick<ModalProps, 'externalControls'>;

const CancellationFlow: React.FC<Props> = ({
    externalControls,
    isLoading = false,
    onCompleteCallback,
    onCloseModal
}) => {
    const [, setExternalState] = externalControls ?? [];

    const { step, steps, heading, state, eyebrow } = useCancellationStateMachine();

    const handleEndFlow = useCallback(() => {
        typeof onCompleteCallback === 'function' && onCompleteCallback();
        setExternalState && setExternalState(false);
    }, [setExternalState, onCompleteCallback]);

    const modalType: ModalProps['type'] = useMemo(() => {
        const warn = [
            'review',
            'mitigation',
            'mitigation-offers',
            'plan-offers',
            'collect-payment-method'
        ];
        const success = ['success', 'mitigation-success', 'rtm-to-d2c-success'];
        const modalState = state || '';

        if (warn.includes(modalState)) {
            return 'warn';
        }
        if (success.includes(modalState)) {
            return 'success';
        }
        return 'neutral';
    }, [state]);

    const modalPadding = useSectionPadding({ padding: 'regular' });
    const innerWrapPadding = useComponentPadding({ padding: 'regular' });

    const modalStyles: CSSProperties = {
        width: '100rem',
        ...modalPadding
    };

    const innerWrapStyles: CSSProperties = {
        alignSelf: 'stretch',
        ...innerWrapPadding
    };

    const handleClose = useCallback(() => {
        onCloseModal && onCloseModal();
        ['success', 'disabled-cancel', 'mitigation-success', 'rtm-to-d2c-success'].includes(
            state || ''
        ) &&
            onCompleteCallback &&
            onCompleteCallback();
    }, [state, onCompleteCallback]);

    return (
        <Modal
            width="page"
            externalControls={externalControls}
            type={modalType}
            styles={modalStyles}
            noDefaultPadding
            isCancelable
            onClose={handleClose}
        >
            <CancellationBanner />
            <ModalFlowHeader heading={heading} eyebrow={eyebrow} steps={steps} currentStep={step} />
            {isLoading ? (
                <InlineLoader />
            ) : (
                <div style={innerWrapStyles}>
                    <ModalSteps state={state} handleEndFlow={handleEndFlow} />
                </div>
            )}
        </Modal>
    );
};

const CancellationFlowWithContext: React.FC<Props> = ({
    isOrganizationMember = false,
    isCreateNewRma = false,
    disableCreateRma = false,
    externalControls,
    isLoading = false,
    showDischargedWarning = false,
    onCompleteCallback,
    onCloseModal,
    membership,
    rmaEnums,
    userDetails,
    rma,
    cancellationDetails,
    cancellationOptions,
    enterpriseEnrollment,
    orgCancellationDetails,
    orgCancellationOptions
}) => (
    <CancellationStateMachineProvider
        isOrganizationMember={isOrganizationMember}
        isCreateNewRma={isCreateNewRma}
        membership={membership}
        enrollment={enterpriseEnrollment}
        rmaEnums={rmaEnums}
        userDetails={userDetails}
        cancellationDetails={cancellationDetails}
        orgCancellationDetails={orgCancellationDetails}
        cancellationOptions={cancellationOptions}
        orgCancellationOptions={orgCancellationOptions}
        rma={rma}
        disableCreateRma={disableCreateRma}
        showDischargedWarning={showDischargedWarning}
    >
        <CancellationFlow
            isLoading={isLoading}
            isOrganizationMember={isOrganizationMember}
            externalControls={externalControls}
            onCompleteCallback={onCompleteCallback}
            onCloseModal={onCloseModal}
        />
    </CancellationStateMachineProvider>
);

export default CancellationFlowWithContext;
