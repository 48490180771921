import Tab from '@hero/ui-kit/components/Tab';
import H from '@hero/ui-kit/typography/H';
import React from 'react';

interface HeaderProps {
    externalSerial: string | null;
    deviceId: number;
    isRefurbishmentAdmin?: boolean;
    isAppSupportAdminAdmin?: boolean;
}

const Header: React.FC<HeaderProps> = ({
    externalSerial,
    deviceId,
    isAppSupportAdminAdmin = false,
    isRefurbishmentAdmin = false
}) => {
    return (
        <>
            <H role="h5">{`Device ${externalSerial || 'Not Provided'}`}</H>
            <div style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
                <Tab to={`/devices/${deviceId}/details`}>Device Details</Tab>
                {isRefurbishmentAdmin && externalSerial ? (
                    <Tab to={`/devices/${deviceId}/refurbishment`}>Refurbishment</Tab>
                ) : null}
                {isAppSupportAdminAdmin ? (
                    <Tab to={`/devices/${deviceId}/change-role`}>Change Role</Tab>
                ) : null}
                <Tab to={`/devices/${deviceId}/websocket-logs`}>Device Websocket Logs</Tab>
            </div>
        </>
    );
};

export default Header;
