import React from 'react';
import useDeviceDetailsData from '../useDeviceDetailsData';
import DefaultLayout from '../../../../components/DefaultLayout';
import DefaultErrorModal from '../../../../components/DefaultErrorModal';
import Section from '@hero/ui-kit/layout/Section';
import Header from '../Header';
import InlineLoader from '../../../../components/InlineLoader';
import DeviceWebsocketLogs from './DeviceWebsocketLogs';

const DeviceWebsocketLogsPage: React.FC = () => {
    const {
        deviceDetails,
        isLoading,
        hasError,
        errorMessage,
        isAppSupportAdminAdmin,
        isRefurbishmentAdmin
    } = useDeviceDetailsData();

    return (
        <DefaultLayout>
            {hasError && <DefaultErrorModal errorMessage={errorMessage} />}
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <Header
                    isAppSupportAdminAdmin={isAppSupportAdminAdmin}
                    isRefurbishmentAdmin={isRefurbishmentAdmin}
                    externalSerial={deviceDetails.external_serial}
                    deviceId={deviceDetails.id}
                />
                {isLoading ? <InlineLoader /> : <DeviceWebsocketLogs deviceDetails={deviceDetails} />}
            </Section>
        </DefaultLayout>
    );
};

export default DeviceWebsocketLogsPage;
