import React, { CSSProperties, useCallback } from 'react';

import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import Section from '@hero/ui-kit/layout/Section';
import Form, { useFormContext, useWatch } from '@hero/ui-kit/inputs/Form';
import Button from '@hero/ui-kit/inputs/Button';
import Input from '@hero/ui-kit/inputs/Input';
import H from '@hero/ui-kit/typography/H';
import changeRoleSchema, { ChangeRoleParams } from './validator/changeSchema';
import { DeviceDetails, RoleChangeOptions } from '@hero/hero-types';
import useUpdateUserRole from '../api/useUpdateUserRole';
import P from '@hero/ui-kit/typography/P';
import Radio from '@hero/ui-kit/inputs/Radio';
import { useDispatch } from 'react-redux';
import { fetchDeviceDetails } from '@hero/redux-data/backoffice/deviceDetails/actionCreators';
import QueryInlineError from '../../../../components/QueryInlineError';
import DefaultSelect from '@hero/ui-kit/inputs/DefaultSelect';

type Props = {
    deviceDetails: DeviceDetails;
    roleChangeOptionsInDeviceDetails: RoleChangeOptions | null;
} & Pick<ModalProps, 'externalControls'>;

const FormInputs: React.FC<{
    roleChangeOptionsInDeviceDetails: RoleChangeOptions | null;
    isLoading?: boolean;
    onClose?: () => void;
}> = ({ roleChangeOptionsInDeviceDetails, isLoading = false, onClose }) => {
    const switchTo = useWatch({ name: 'switch_to' });
    const caregiverEmail = useWatch({ name: 'caregiver_email' });

    const {
        setValue,
        formState: { isValid }
    } = useFormContext();

    React.useEffect(() => {
        if (typeof caregiverEmail === 'string' && caregiverEmail.length) {
            setValue('email', caregiverEmail !== 'other' ? caregiverEmail : '');
        }
    }, [caregiverEmail, setValue]);

    const roles = React.useMemo(() => {
        return roleChangeOptionsInDeviceDetails?.primary_user_admin
            ? [
                  {
                      value: 'caregiver',
                      label: 'Caregiver'
                  }
              ]
            : [
                  {
                      value: 'primary_user',
                      label: 'Primary user'
                  },
                  {
                      value: 'caregiver',
                      label: 'Caregiver'
                  }
              ];
    }, [roleChangeOptionsInDeviceDetails]);

    const caregivers = React.useMemo(() => {
        const allCaregivers =
            roleChangeOptionsInDeviceDetails?.caregivers.map((email) => ({
                isDisabled: email === roleChangeOptionsInDeviceDetails.caregiver_admin,
                label:
                    email === roleChangeOptionsInDeviceDetails.caregiver_admin
                        ? `${email} - admin`
                        : email,
                value: email
            })) || [];

        return [...allCaregivers, { isDisabled: false, value: 'other', label: 'Other' }];
    }, [roleChangeOptionsInDeviceDetails]);

    const showInputEmail = React.useMemo(() => {
        if (
            !roleChangeOptionsInDeviceDetails?.primary_user_is_placeholder &&
            switchTo === 'primary_user'
        ) {
            return false;
        }
        return (
            (switchTo === 'caregiver' && caregiverEmail === 'other') ||
            switchTo === 'primary_user' ||
            roleChangeOptionsInDeviceDetails?.caregivers.length === 0
        );
    }, [roleChangeOptionsInDeviceDetails, switchTo, caregiverEmail]);

    return (
        <>
            <DefaultSelect
                name="switch_to"
                displayName="Change admin role to"
                styles={{ minWidth: '48rem' }}
                options={roles}
            />
            {switchTo === 'caregiver' && roleChangeOptionsInDeviceDetails?.caregivers.length ? (
                <>
                    {caregivers.map((caregiver, index) => (
                        <Radio
                            key={`${caregiver.value}-${index}`}
                            id={caregiver.value}
                            value={caregiver.value}
                            labelText={caregiver.label}
                            disabled={caregiver.isDisabled}
                            name="caregiver_email"
                            styles={{ width: '48rem' }}
                            checkedValue={caregiverEmail}
                        />
                    ))}
                </>
            ) : null}
            {showInputEmail ? (
                <Input
                    name="email"
                    displayName="Email"
                    placeholder="Enter email - must be an existing user"
                />
            ) : null}

            <div style={{ marginTop: '1.2rem' }}>
                <Button width="large" type="submit" disabled={isLoading || !isValid}>
                    Save
                </Button>
                <Button width="large" variant="secondary" onClick={onClose} disabled={isLoading}>
                    Cancel
                </Button>
            </div>
        </>
    );
};

const ChangeRoleModal: React.FC<Props> = ({
    externalControls,
    deviceDetails,
    roleChangeOptionsInDeviceDetails
}) => {
    const [, setExternalState] = externalControls ?? [];
    const dispatch = useDispatch();

    const handleClose = useCallback(() => {
        setExternalState && setExternalState(false);
        dispatch(fetchDeviceDetails({ device_id: deviceDetails.id }));
    }, [setExternalState, dispatch, deviceDetails]);

    const { mutate, isError, isPending, isSuccess, error } = useUpdateUserRole(deviceDetails.id);

    const innerWrapperStyles: CSSProperties = {
        width: '48rem'
    };

    const handleSubmit = React.useCallback(
        (formValues: ChangeRoleParams) => {
            const attributes = {
                switch_to: formValues.switch_to,
                ...(formValues.switch_to === 'caregiver'
                    ? { caregiver_email: formValues.email }
                    : formValues.email?.length
                      ? { new_primary_user_email: formValues.email }
                      : {})
            };

            mutate(attributes);
        },
        [mutate]
    );

    return (
        <Modal
            externalControls={externalControls}
            isCancelable
            type={isSuccess ? 'success' : 'neutral'}
            onClose={handleClose}
        >
            {isSuccess ? (
                <>
                    <P centered size="large" strong>
                        {`Successfully changed the role for ${deviceDetails.external_serial}`}
                    </P>
                    <Button onClick={handleClose} width="full">
                        Close
                    </Button>
                </>
            ) : (
                <>
                    <H role="h4" centered>
                        {`Change Admin role for device serial: ${
                            deviceDetails.external_serial || 'Not provided'
                        }`}
                    </H>
                    <Form
                        validationSchema={changeRoleSchema}
                        submitFn={handleSubmit}
                        defaultValues={{
                            primary_user_is_placeholder:
                                roleChangeOptionsInDeviceDetails?.primary_user_is_placeholder ||
                                false
                        }}
                    >
                        <Section noDefaultPadding centered styles={innerWrapperStyles}>
                            <FormInputs
                                roleChangeOptionsInDeviceDetails={roleChangeOptionsInDeviceDetails}
                                isLoading={isPending}
                                onClose={handleClose}
                            />
                            {isError ? <QueryInlineError centered error={error} /> : null}
                        </Section>
                    </Form>
                </>
            )}
        </Modal>
    );
};

export default ChangeRoleModal;
