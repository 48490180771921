import useTheme from '@hero/branding/theme';
import React from 'react';
import { useCancellationStateMachine } from './context/cancellationStateMachine';

interface CancellationBannerProps {}

const CancellationBanner: React.FC<CancellationBannerProps> = () => {
    const { isOrganizationMember, mitigationDetails, state } = useCancellationStateMachine();
    const { colors } = useTheme();

    const bannerText = React.useMemo(() => {
        if (isOrganizationMember) {
            return 'Mitigation not available for Org members.';
        }

        if (!mitigationDetails.isMitigationPlan) {
            return 'Mitigation not available for this membership plan.';
        }

        if (mitigationDetails.isInPrepaidPeriod) {
            return 'Mitigation not available - The user is in prepaid period.';
        }

        if (mitigationDetails.isInPriceIncreaseFlow) {
            return 'Mitigation not available - The user is in Price Increase Flow.';
        }

        return null;
    }, [mitigationDetails, isOrganizationMember]);

    return bannerText && state !== 'discharge-warning' ? (
        <div
            style={{
                background: colors.brand.primary.alpha,
                textAlign: 'center',
                fontSize: '18px',
                padding: '10px',
                borderRadius: '8px',
                fontWeight: 'bold',
                maxWidth: '80rem',
                color: 'whitesmoke'
            }}
        >
            {bannerText}
        </div>
    ) : null;
};

export default CancellationBanner;
